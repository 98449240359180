/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// *:not(mat-icon, .mat-tooltip, .mat-checkbox, mat-chip, mat-chip > div, .range-tooltip > div > p > strong, .range-tooltip > p > strong, .api-error-color, .sub_div > span, .sub_div > strong, .auth-snackbar, .files-btn > span){
//   color: var(--text-color) !important;
// }

:not(mat-icon):not(.mat-tooltip):not(.mat-checkbox):not(mat-chip):not(mat-chip > div):not(.range-tooltip > div > p > strong):not(.range-tooltip > p > strong):not(.api-error-color):not(.sub_div > span):not(.sub_div > strong):not(.files-btn > span) {
  color: var(--text-color);
}

.mat-snack-bar-container {
  background: #808080!important;
}

.files-btn span {
  color: white!important;
}


.api-error-color{
  color: black;
}

app-dettaglio-polizza .mat-icon-no-color:not(#fullscreen-btn>span>mat-icon) {
  color: var(--primary) !important;
}

#aggregato-polizze-fab>span>mat-icon{
  color: white !important;
}

#fullscreen-btn>span>mat-icon{
  color: white !important;
}

.mat-expansion-indicator::after, .mat-expansion-indicator::before{
  color: var(--text-color) !important;
}

tr:not(.mat-header-row):hover{
  background-color: var(--row-hover) !important;
}

.list-users:hover{
  background-color: var(--row-hover) !important;
}

.custom-paginator-numbers[disabled]{
  background-color: var(--row-hover) !important;
}

.custom-paginator-numbers:hover{
  background-color: var(--row-hover) !important;
}

.mov-line, .mov-line-dialog{
  fill: var(--text-color) !important;
}

#d3-tooltip {
  background-color: var(--background-tooltip) !important;
}

#range-chart-tooltip{
  background-color: var(--background-tooltip) !important;
}

.new-line-tooltip{
  background-color: var(--background-tooltip) !important;
}

.new-tooltip{
  background-color: var(--background-tooltip) !important;
}

mat-selection-list{
  background-color: transparent !important;
}

mat-card{
  background-color: var(--card-color) !important;
}

mat-sidenav{
  background-color: var(--sidenav-color) !important;
}

mat-sidenav-content{
  background-color: var(--background-color);
}

mat-dialog-container{
  background-color: var(--overlay-bg-color) !important;
}

.mat-select-panel{
  background-color: var(--overlay-bg-color) !important;
}

.mat-menu-content{
  background-color: var(--overlay-bg-color) !important;
}

mat-expansion-panel:not(app-dettaglio-polizza > mat-expansion-panel){
  background-color: var(--card-color) !important;
}

mat-datepicker-content{
  background-color: var(--overlay-bg-color) !important;
}

.mat-calendar-arrow {
  fill: var(--text-color) !important;
}

mat-form-field{
  color: var(--primary) !important;
}

.icon-container > mat-icon {
  color: var(--icon-color-on-bg) !important;
}

.sub_div > span, .sub_div > strong {
  color: white !important;
}

.mat-chip {
  color: var(--icon-color-on-bg) !important;
}

mat-toolbar > button > span > mat-icon {
  color: var(--icon-color-on-bg) !important;
}

mat-toolbar > .links > button > span > mat-icon {
  color: var(--icon-color-on-bg) !important;
}

mat-toolbar > span {
  color: var(--icon-color-on-bg) !important;
}

table{
  background-color: transparent !important;
}

mat-paginator{
  background-color: transparent !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.m-top {
  margin-top: 1.5em;
}

.m-bottom {
  margin-bottom: 1.5em;
}

.mx-2 {
  margin: 0 1rem;
}

.mx-1 {
  margin: 0 0.5rem;
}

.my-1 {
  margin: 0.5rem 0;
}

.m-1 {
  margin: 0.5rem 0.5rem;
}

.px-1 {
  padding: 0 0.5rem;
}

.py-1 {
  padding: 0.5rem 0;
}

.container{
  margin-top: 4.58em;
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.component{
  width: 100%;
  height: calc(100vh - 4.58em);
}

@media screen and (max-width: 1280px){
  .component{
    width: 100%;
  }
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: var(--primary);
}

::selection {
  color: white;
  background: var(--primary);
}

.active-link {
  color: var(--primary);
  font-weight: bold;
}

.colored-margin-left {
  border-left: 3px solid var(--primary);
  background-color: var(--row-hover);
}


#Fondi, #Analisi-Portafoglio, #Movimenti, #Comunicazioni, #Informativa {
  scroll-margin: 5em;
}

#Dettaglio-Polizza {
  scroll-margin: 6.08em;
}

th.mat-header-cell{
  font-weight: bold;
}

/* ===== Scrollbar CSS ===== */
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--primary);
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 5px;
    border: 1px solid #ffffff00;
  }

  .sidenav-scrollbar::-webkit-scrollbar-track{
    margin-top: 4.58em;
  }

  .normal-scrollbar::-webkit-scrollbar-track{
    margin-top: 0 !important;
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }

// Spinner circolare
  .sk-circle {
    margin: 100px auto;
    width: 5em;
    height: 5em;
    position: relative;
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    // background-color: var(--primary);
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg); }
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg); }
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg); }
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg); }
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg); }
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg); }
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg); }
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); }
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; }
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; }
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s; }
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s; }
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s; }

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
//





